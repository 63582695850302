import { SignIn } from "@clerk/react-router";
import { dark } from "@clerk/themes";
import { useDarkMode } from "~/hooks/useDarkMode";

export default function SignInPage() {
  const darkMode = useDarkMode();
  return (
    <div className="h-full flex justify-center">
      <div className="mt-32">
        <SignIn appearance={{ baseTheme: darkMode ? dark : undefined }} />
      </div>
    </div>
  );
}
